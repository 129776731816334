<template>
  <div class="main">
    <div class="home">
      <div class="ant-spin-nested-loading" style="width: 100%">
        <div class="ant-spin-container">
          <div
            class="app-container"
            style="margin: 10px auto; flex-direction: column"
          >
            <div class="freeCaseTitle">
              <div class="titleContent">
                <div class="info" data-v-7b2273ab="">
                  <img
                    src="../assets/img/freeBox/titleI.png"
                    alt=""
                    data-v-7b2273ab=""
                  />
                  <p data-v-7b2273ab="">免费开箱</p>
                </div>
              </div>
            </div>

            <div class="page__cases">
              <!-- <div  class="case">
                <div  class="case__background"></div>
                <div  class="case__pictures">
                  <div
                    
                    data-src="http://api.r8skins.com/uploads/20210504/16642554e978623def8f88eed84d183c.png"
                    lazy="loaded"
                    class="case__case-picture"
                    style="
                      background-image: url('http://api.r8skins.com/uploads/20210504/16642554e978623def8f88eed84d183c.png');
                    "
                  ></div>
                  <div
                    
                    data-src="http://api.r8skins.com/uploads/20210429/656a3986ffcfaff89a6559571de8e63b.png"
                    lazy="loaded"
                    class="case__award-picture"
                    style="
                      background-image: url('http://api.r8skins.com/uploads/20210429/656a3986ffcfaff89a6559571de8e63b.png');
                    "
                  ></div>
                </div>
                <div  class="case__info">
                  <span  class="case__info__label">ONE</span
                  ><span
                    
                    class="case__info__currenty-icon"
                    style="background-image: url('/img/coin.5823c98a.png')"
                  ></span
                  ><span  class="case__info__price"
                    >等级一</span
                  >
                </div>
                <div  class="picture-container case__light">
                  <img
                    
                    src="../assets/img/box_bg_3.gif"
                    alt=""
                    class="gif_class none active"
                    style="mix-blend-mode: screen"
                  /><img
                    
                    src="../assets/img/box_bg_4.jpg"
                    alt=""
                    class="no_gif_class none"
                    style="mix-blend-mode: screen"
                  />
                </div>
                <div
                  
                  class="case__open-price"
                  style="background-image: url('/img/tips1.883ea7c6.png')"
                >
                  <span  class="case__open-price__text"
                    >
                    等级一
                  </span>
                </div>
              </div> -->
              <div
                class="case"
                v-for="(items, indexs) in getboxdatalist"
                :key="indexs"
                @click="
                  $router.push({
                    path: '/freeBoxList',
                    query: { id: items.id },
                  })
                "
              >
                <div class="case__background"></div>
                <div class="case__pictures">
                  <div
                    :data-src="items.back_logo"
                    lazy="loaded"
                    class="case__case-picture"
                    :style="{ backgroundImage: 'url(' + items.back_logo + ')' }"
                  ></div>
                  <div
                    :data-src="items.logo"
                    lazy="loaded"
                    class="case__award-picture"
                    :style="{ backgroundImage: 'url(' + items.logo + ')' }"
                  ></div>
                </div>
                <!-- <h2  class="case__title"> 1</h2> -->
                <div class="case__info">
                  <span class="case__info__label">OPEN</span
                  ><span
                    class="case__info__currenty-icon"
                    style="background-image: url('_')"
                  ></span
                  ><span class="case__info__price">{{ items.title }}</span>
                </div>
                <div class="picture-container case__light">
                  <img
                    src="https://static.v5boss.com/static/img/common/box_bg_3.gif"
                    alt=""
                    class="gif_class none active"
                    style="mix-blend-mode: screen"
                  /><img
                    src="https://static.v5boss.com/static/img/common/box_bg_4.jpg"
                    alt=""
                    class="no_gif_class none"
                    style="mix-blend-mode: screen"
                  />
                </div>
                <div
                  class="case__open-price"
                  style="background-image: url('_')"
                >
                  <span class="case__open-price__text">
                    {{ items.title }}
                  </span>
                </div>
              </div>
            </div>

            <!-- 等级 -->
            <div class="freeCaseTitle">
              <div class="titleContent">
                <div class="info" data-v-7b2273ab="">
                  <img
                    src="../assets/img/freeBox/titleI.png"
                    alt=""
                    data-v-7b2273ab=""
                  />
                  <p data-v-7b2273ab="">你的等级</p>
                </div>
              </div>
            </div>
            <div class="userLevel">
              <div class="level">
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr1.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="levelIconP">等级1</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[1] != 0">
                          <span class="nextLevelspan">{{ userCoin[1] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 1</span>
                    </div>
                  </div>
                  <!---->
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr2.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级2</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[2] != 0">
                          <span class="nextLevelspan">{{ userCoin[2] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 2</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr3.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级3</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span> 再充值</span>
                      <template v-if="userCoin[3] != 0">
                        <span class="nextLevelspan">{{ userCoin[3] }}</span>
                      </template>

                      <span>以获取</span>
                      <span class="nextLevel">等级 3</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr4.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级4</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[4] != 0">
                          <span class="nextLevelspan">{{ userCoin[4] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 4</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr5.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级5</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[5] != 0">
                          <span class="nextLevelspan">{{ userCoin[5] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 5</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
              </div>
            </div>
            <div class="userLevel">
              <div class="level">
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr6.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级6</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值</span>
                      <div>
                        <template v-if="userCoin[6] != 0">
                          <span class="nextLevelspan">{{ userCoin[6] }}</span>
                        </template>
                      </div>
                      <span>以获取</span>
                      <span class="nextLevel">等级 6</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr7.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级7</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[7] != 0">
                          <span class="nextLevelspan">{{ userCoin[7] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 7</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr8.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级8</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[8] != 0">
                          <span class="nextLevelspan">{{ userCoin[8] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 8</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr9.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级9</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[9] != 0">
                          <span class="nextLevelspan">{{ userCoin[9] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 9</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
                <div>
                  <div class="levelIcon">
                    <img
                      src="../assets/img/freeBox/fr10.png"
                      alt=""
                      class="normalIcon normalIcons"
                    />
                  </div>
                  <p class="">等级10</p>
                  <div class="fillLevelBig">
                    <div class="fillLevel">
                      <span>再充值 </span>
                      <div>
                        <template v-if="userCoin[10] != 0">
                          <span class="nextLevelspan">{{ userCoin[10] }}</span>
                        </template>
                      </div>
                      <span>以获取 &nbsp;</span>
                      <span class="nextLevel">等级 10</span>
                    </div>
                  </div>
                  <!---->
                  <div class="seat"></div>
                </div>
              </div>
            </div>

            <!-- 升级介绍 -->
            <!-- <div class="freeCaseTitle">
              <div class="titleContent">
                <div class="info" data-v-7b2273ab="">
                  <img
                    src="../assets/img/freeBox/titleI.png"
                    alt=""
                    data-v-7b2273ab=""
                  />
                  <p data-v-7b2273ab="">FAQ</p>
                </div>
              </div>
            </div> -->

            <div class="faqMain">
              <div>
                <div class="faqItem" @click="faqItem1">
                  <div data-v-46f70e1a="" class="cardTop">
                    <div data-v-46f70e1a="" class="cardLeft">
                      <span data-v-46f70e1a="">关于免费开箱</span>
                    </div>
                    <div data-v-46f70e1a="" class="cardRight">
                      <span
                        data-v-46f70e1a=""
                        class="arrow"
                        :class="{ arrowTop: active1 }"
                      ></span>
                    </div>
                  </div>
                  <!-- <div data-v-46f70e1a="" class="cardClose" :class="{'cardOpen':active1}"> -->
                  <div
                    data-v-46f70e1a=""
                    class="cardClose"
                    :class="{ cardOpen: active1 }"
                  >
                    <div data-v-46f70e1a="" class="cardClosema">
                      <h2 style="margin-left: 40px padding-bottom: 10px;">
                        <span style="color: #ecf0f1"
                          ><span style="font-size: 16px; margin-left: 40px"
                            >谁可以开免费箱子?</span
                          ></span
                        >
                      </h2>

                      <p style="margin-left: 40px; padding-bottom: 10px">
                        <br />
                        <span style="font-size: 14px"
                          >免费箱子对我们所有的用户都适用，但您需要至少达到等级1，才能打开它。免费箱子一共有10个级别，您可以通过充值来提升您可以打开的免费箱子的级别。每个用户每个等级可以打开3次对应等级的免费箱子。</span
                        >
                      </p>

                      <p style="margin-left: 40px">&nbsp;</p>

                      <h2 style="margin-left: 40px">
                        <span style="font-size: 16px"
                          ><span style="color: #ecf0f1; font-size: 16px"
                            >什么是免费箱子等级和等级余额?</span
                          ></span
                        >
                      </h2>

                      <p style="margin-left: 40px">
                        <br />
                        <span style="font-size: 14px"
                          >免费箱子一共分为十个级别。您的等级取决于您的等级余额。每当您提升一个等级，都会解锁一个新的箱子。您的等级越高，箱子中可以开出的皮肤价值越高。以下是关于免费箱子等级和等级余额的详细列表：</span
                        >
                      </p>

                      <p style="margin-left: 40px">&nbsp;</p>

                      <!-- <p style="margin-left: 40px">
                        <span style="font-size: 14px">等级 1 - $5</span>
                      </p> -->

                      <p
                        style="margin-left: 40px"
                        v-for="(itemsl, indexsl) in getboxdatalist"
                        :key="indexsl"
                      >
                        <br />
                        <span style="font-size: 14px"
                          >{{ itemsl.title }} - ${{ itemsl.coin }}</span
                        >
                      </p>

                      <p style="margin-left: 40px">
                        <!-- <br />
                        <span style="font-size: 14px">等级 5 - $200</span><br />
                        <br />
                        <span style="font-size: 14px"
                          >等级 6 - $500<br />
                          <br />
                          等级 7 - $1000<br />
                          <br />
                          等级 8 - $2000</span
                        ><br />
                        <br />
                        <span style="font-size: 14px"
                          >等级 9 - $3500<br />
                          <br />
                          等级 10 - $5500</span
                        ><br /> -->
                        <br />
                        <br />
                        <br />
                        <span style="color: #ecf0f1"
                          ><span style="font-size: 16px"
                            >我怎么样才能提升等级?</span
                          ></span
                        >
                      </p>

                      <h2 style="margin-left: 40px">
                        <br />
                        <template v-if="userLevel">
                          <span style="font-size: 14px" v-if="getboxdatalist"
                            >您的等级余额积累越多，您的等级就会越高.。当您在为账户余额充值时，您就会增加相同的等级余额。例如：当您累计充值{{
                              getboxdatalist[1].coin
                            }}的时候，您就可以解锁等级{{
                              getboxdatalist[1].title
                            }}的免费箱子，以此类推！！！</span
                          >
                        </template>
                      </h2>

                      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>

                      <h2 style="margin-left: 40px; font-size: 17px">
                        关于免费箱子的次数：<span
                          style="color: #e74c3c; font-size: 17px"
                          >用户每达到相对应等级箱子条件可免费开三次</span
                        >
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active1: true, //等级介绍
      getboxdata: "", //页面箱子数据
      getboxdatalist: "", //页面箱子数据
      userLevel: "", //用户等级数据
      userCoin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  },
  watch: {},
  methods: {
    //等级介绍
    faqItem1() {
      this.active1 = !this.active1;
    },
    getLevel() {
      //确定等级后添加对应的颜色和文字
      for (let i = 0; i < this.userLevel.level - 1; i++) {
        document
          .getElementsByClassName("normalIcons")
          [i].classList.remove("normalIcon");
      }
      //显示下面的文字
      document.getElementsByClassName("fillLevel")[
        this.userLevel.level - 1
      ].style.display = "flex";
      this.userCoin[this.userLevel.level] = this.userLevel.coin;
      for (let i = this.userLevel.level + 1; i < 11; i++) {
        let temp_coin =
          this.getboxdatalist[i - 1].coin - this.userLevel.allCoin;
        document.getElementsByClassName("fillLevel")[i - 1].style.display =
          "flex";
        this.$set(this.userCoin, i, temp_coin);
      }
      if (this.userCoin[10] == 0) {
        document.getElementsByClassName("fillLevel")[9].style.display = "";
        document.getElementsByClassName("fillLevel")[9].style.display ="";
        document.getElementsByClassName("normalIcons")[9].classList.remove("normalIcon");
      }
      
    },
    //获取页面数据
    getbox() {
      this.$axios({
        url: "pond/free/List",
        method: "post",
      }).then((res) => {
        if (res.status == 200) {
          this.getboxdata = res.data;
          this.getboxdatalist = this.getboxdata[0].list;
        }
      });
    },
    //获取等级
    getuserLevel() {
      this.$axios({
        url: "user/get/UserLevel",
        method: "post",
      }).then((res) => {
        if (res.data.status == 200) {
          this.userLevel = res.data.data;
        }
      });
    },
  },

  mounted() {
    setTimeout(() => {
      this.getLevel();
    }, 1000);
    this.getbox();
    this.getuserLevel();
  },
};
</script>

<style scoped lang='less'>
.freeCaseTitle {
  width: 100%;
  height: 6.25rem;
  display: flex;
  align-items: center;
}
.titleContent {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  img {
    width: 4.375rem;
  }
  p {
    font-size: 1.375rem;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #d0d2e0;
    margin-left: 0.875rem;
    padding-bottom: 5px;
  }
}
.userLevel {
  height: 17.6875rem;
  background: #1f202e;
  position: relative;
  margin-bottom: 1.25rem;
}
.level {
  width: 70.25rem;
  height: 5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 7.25rem;
}
.level > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.levelIcon {
  width: 5rem;
  height: 5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
    font-size: 0.875rem;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #fff;
    margin-top: 1.25rem;
  }
  img {
    width: 100%;
  }
  .normalIcon {
    filter: grayscale(1);
  }
}
// .levelIconP{
//     text-align: center;
//     font-size: 0.875rem;
//     font-family: Microsoft YaHei;
//     font-weight: 700;
//     color: #fff;
//     margin-top: 1.25rem;
// }
.case {
  width: 20%;
}

.faqItem {
  width: 74.5625rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 3.125rem;
  padding-right: 1.5625rem;
  background: #1f202e;
}
.cardTop {
  cursor: pointer;
  width: 100%;
  height: 5.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardLeft {
  span {
    font-size: 1.125rem;
    font-family: PingFang SC;
    font-weight: 700;
    color: #fff;
  }
}
.cardRight {
  display: flex;
  align-items: center;
}
.arrow {
  width: 0;
  height: 0;
  border: 0.5625rem solid transparent;
  border-top: 0.875rem solid #a9a9a9;
  margin-left: 1.5625rem;
  margin-top: 0.625rem;
}
.arrowTop {
  border-top-color: transparent;
  border-bottom: 0.875rem solid #970bff;
  margin-top: 0;
  margin-bottom: 0.625rem;
}
.cardClose {
  height: 0;
  padding: 0;
  overflow: hidden;
}
.cardClose,
.cardOpen {
  transition: all 0.5s linear;
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9b9aa1;
}
.cardOpen {
  padding-top: 1.8125rem;
  padding-bottom: 2.75rem;
  height: auto;
  border-top: 0.0625rem solid #393b51;
}
.case__info {
  padding: 4px 5px !important;
}
.fillLevelBig {
  height: 21px;
  position: absolute;
  bottom: -35%;
  left: -60%;
  width: 220px;
}
.fillLevel {
  // display: flex;
  display: none;
  .nextLevel {
    color: #970bff;
    padding-left: 7px;
  }
  .nextLevelspan {
    padding: 0 5px;
  }
}
.case__open-price__text {
  top: 30%;
  right: 0;
  bottom: 0;
  left: 30%;
}
//奖品图片变小一点
.case__award-picture {
  width: 80% !important;
  left: 10% !important;
}
@media screen and (max-width: 800px) {
  .level {
    width: 100%;
  }
  .fillLevel {
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
  }
  .case {
    width: 50%;
  }
  .faqItem {
    width: 100%;
  }
  .cardClosema {
    margin-left: -40px;
  }
  .nextLevel {
    padding-left: 0px !important;
  }
  .fillLevelBig {
    position: relative;
    left: 0;
    bottom: 0;
    width: 50px;
  }
}
</style>
